<template>
  <div class="fee-box">
    <div class="head">费用构成</div>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.fee-box {
  margin: 20px;
  font-size: 14px;
  color: #666;
}

.head {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  position: relative;
}

.head::before {
  position: absolute;
  content: "";
  left: -12px;
  top: 2px;
  background: url("../../../assets/imgs/calc/bias.png") no-repeat;
  width: 10px;
  height: 20px;
}
</style>