<template>
  <div class="item-box">
    <div class="item">
      <div class="title">
        <img v-if="tip != null" src="@/assets/imgs/calc/link.png" class="tip" @click="$emit('tip', tip)"/>
        <span>{{ title }}:</span>
      </div>
      <div class="content">
        <span>{{ content }}</span>
        <span class="desc" v-if="desc">{{'(' + desc + ')'}}</span>
      </div>
    </div>
    <van-divider />
  </div>
</template>

<script>
import { Divider } from "vant";
export default {
  props: ["title", "content", "desc", "tip"],
  components: {
    [Divider.name]: Divider,
  },
};
</script>

<style scoped>
.item-box >>> .van-divider {
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 10px 0;
}

.title {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: top;
  margin-right: 10px;
  text-align: right;
}

.tip {
  width: 14px;
  height: 14px;
}

.content {
  flex: 1.5;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #333333;
  margin-left: 10px;
}

.desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 4px;
  color: #658CF1;
}
</style>