<template>
  <div>
    <calc-result-page>
      <div class="res-box">
        <div>工伤保险计算结果为：</div>
        <div class="money-box">{{res.totalFee}}<span>元</span></div>
      </div>
      <fee-consititute-container>
        <template v-if="res.treatmentList !== null">
          <res-list-item
            v-for="item in res.treatmentList"
            :key="item.name"
            :title="item.name"
            :content="item.fee != 0 ? item.fee : item.feeText"
            :tip="item.markDesc"
            :desc="item.payType"
            @tip="onTip"
          ></res-list-item>
        </template>

        <template v-if="res.medicalFeeList !== null">
          <res-list-item
            v-for="item in res.medicalFeeList"
            :key="item.name"
            :title="item.name"
            :content="item.fee != 0 ? item.fee : item.feeText"
            :tip="item.markDesc"
            :desc="item.payType"
            @tip="onTip"
          ></res-list-item>
        </template>
      </fee-consititute-container>

      <div class="tip">以上结果只是测算，最终以社保局计算结果为准</div>
    </calc-result-page>
  </div>
</template>

<script>
import CalcResultPage from "@/components/CalcResultPage";
import ResListItem from "@/views/calc/components/ResListItem";
import FeeConsitituteContainer from "@/views/calc/components/FeeConsitituteContainer.vue";

import { Divider, Dialog } from "vant";
export default {
  components: {
    CalcResultPage,
    ResListItem,
    FeeConsitituteContainer,
    [Divider.name]: Divider,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      res: {},
    };
  },
  mounted() {
    const res = this.$route.query.res
    if (res !== undefined) {
      this.res = JSON.parse(this.$route.query.res);
    }
  },
  methods: {
    onTip(tip) {
      Dialog.alert({
        message: tip,
        messageAlign: "left",
      }).then(() => {
        // on close
      });
    },
  },
};
</script>

<style scoped>
.res-box {
  margin-left: 20px;
  color: #4d4d4d;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.header-pay {
  opacity: 0.5;
  font-size: 14px;
  color: #658CF1;
}

.company-pay {
  opacity: 0.5;
  font-size: 14px;
  color: #fd7d02;
}

.money-box {
  font-size: 44px;
  font-weight: bold;
  line-height: 54px;
  color: #658CF1;
}

.money-box span {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #999999;
}

.tip {
  margin-top: 120px;
  margin-left: 40px;
  font-size: 12px;
  color: #658CF1;
}
</style>